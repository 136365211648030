/* VIDEO */
body.page-video {
    background-color: #000;
    overflow: hidden;

    #menu {
        display: none;
    }
    #content {
        padding: 0 !important;
    }
}

#video {
    color: #fff;
    height: 100vh;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100vw;
    z-index: 10;

    video {
        background: #000;
        color: #FFF;
        height: 100vh;
        overflow: hidden;
        width: 100vw;
    }
    video:-webkit-full-screen {
        height: 100% !important;
        overflow: hidden;
        width: 100% !important;
    }
}

/* Icons */
.video-icon {
    align-items: stretch;
    background: rgba(122, 122, 122, 0.5);
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    flex: 0 0 auto;
    height: 2.8rem;
    justify-content: center;
    padding: 0.5rem;
    margin: 0 0.35rem;
    text-align: center;
    transition: all 0.4s ease-in-out;
    width: 2.8rem;

    &.hover,
    &:focus,
    &:active {
        background: #ffc095;
    }

    span {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        flex: 1;
        height: 100%;
        overflow: hidden;
        text-indent: -9999em;
        width: 100%;
    }
    &.video-close span {
        background-image: url(../icons/back.svg);
    }
    &.video-watched span {
        background-image: url(../icons/eye.svg);
    }
    &.video-episodes span {
        background-image: url(../icons/playlist.svg);
    }
    &.video-previous-episode span {
        background-image: url(../icons/previous.svg);
    }
    &.video-next-episode span {
        background-image: url(../icons/next.svg);
    }
    &.video-fullscreen span {
        background-image: url(../icons/fullscreen.svg);
    }
    &.video-pause span {
        background-image: url(../icons/pause.svg);
    }
    &.video-play span {
        background-image: url(../icons/play.svg);
    }
    &.video-reload span {
        background-image: url(../icons/reload.svg);
    }
    &.video-forward span {
        background-image: url(../icons/forward.svg);
    }
    &.video-backward span {
        background-image: url(../icons/backward.svg);
    }
    &.video-skip-intro span {
        background-image: url(../icons/skip.svg);
    }

    &.video-quality {
        border-radius: 0.5em;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.8rem;
        width: 5rem;
    }
    &.video-quality span {
        text-indent: 0;
    }

    &.video-pause {
        display: none;
    }
    &.hide {
        display: none;
    }

}
.mouse #video .video-icon:hover {
    background: #ffc095;
}

/* Elements */
.video-backdrop {
    background: rgba(0, 0, 0, 0.7);
    bottom: 0;
    display: block;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    transition: all 0.4s ease-in-out;
    top: 0;
    z-index: 2;
}

.video-actions {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    left: 0;
    opacity: 1;
    padding: 1rem 1rem 0.5rem;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.4s ease-in-out;
    z-index: 3;

    .video-close {
        justify-self: flex-start;
        margin-right: auto;
        margin-left: 0;
    }
    .video-next-episode {
        margin-right: 0;
    }
}

.video-info {
    bottom: 6rem;
    left: 0;
    opacity: 1;
    padding: 0.5rem 1rem;
    position: absolute;
    right: 9em;
    transition: all 0.4s ease-in-out;
    z-index: 3;

    .video-serie {
        font-size: 0.8rem;
    }
    .video-title {
        padding: 0.25rem 0;
        font-size: 1.4rem;
    }
    .video-error {
        background: #a50404;
        border-radius: 0.25rem;
        color: #FFF;
        display: none;
        margin-top: 0.83rem;
        padding: 10px;
    }
}

.video-time {
    bottom: 6rem;
    right: 0;
    opacity: 1;
    padding: 0.5rem 1rem;
    position: absolute;
    text-align: right;
    transition: all 0.4s ease-in-out;
    width: 9em;
    z-index: 3;
}

.video-controls {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    left: 0;
    opacity: 1;
    padding: 0.5rem 1rem 1rem;
    position: absolute;
    right: 0;
    transition: all 0.4s ease-in-out;
    z-index: 3;

    .video-time {
        font-size: 0.8rem;
        margin-left: 1rem;
    }
}

.video-progress {
    flex: 1 100%;
    height: 8.4px;
    position: relative;
    margin-bottom: 1rem;

    progress,
    input[type=range] {
        position: absolute;
        top: 0;
    }
    .tooltip {
        background-color: #F37521;
        border-radius: 3px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        color: #fff;
        content: attr(data-title);
        display: none;
        font-size: 0.8rem;
        margin-left: -2.8rem;
        padding: 5px 2px;
        position: absolute;
        text-align: center;
        top: -35px;
        width: 4.16rem;
    }
}
.mouse #video .video-progress:hover .tooltip {
    display: block;
}
.mouse #video .video-progress:focus-within .tooltip {
    display: block;
}

/* States */
#video.video-is-playing .video-backdrop,
#video.video-is-playing .video-actions,
#video.video-is-playing .video-quality,
#video.video-is-playing .video-info,
#video.video-is-playing .video-time,
#video.video-is-playing .video-controls {
    opacity: 0;
}
#video.show-controls .video-backdrop,
#video.show-controls .video-actions,
#video.show-controls .video-quality,
#video.show-controls .video-info,
#video.show-controls .video-time,
#video.show-controls .video-controls {
    opacity: 1 !important;
}

#video.video-has-error .video-backdrop,
#video.video-has-error .video-info {
    opacity: 1 !important;
}
#video.video-has-error .video-error {
    display: block;
}

#video.video-is-playing .video-play,
#video.video-is-paused .video-pause {
    display: none;
}
#video.video-is-playing .video-pause,
#video.video-is-paused .video-play {
    display: block;
}