/* DROPDOWN */
.dropdown {
    background: #FFF;
    border: 2px solid transparent;
    border-radius: 5px;
    color: #000;
    padding: 0;
    position: relative;

    input {
        display: none;
    }

    .dropdown-value {
        border-radius: 5px;
        cursor: pointer;
        display: block;
        height: 2.6rem;
        line-height: 2rem;
        overflow: hidden;
        padding: 5px 3rem 5px 1rem;
        position: relative;
        text-align: left;
        transition: border 0.2s ease-in-out;
        width: 100%;

        &:after {
            background: transparent url(../icons/arrow-down.svg) no-repeat;
            background-size: 100% auto;
            content: '';
            display: block;
            height: 0.83rem;
            margin-top: -0.41rem;
            position: absolute;
            right: 1rem;
            top: 50%;
            width: 0.83rem;
            z-index: 12;
        }
    }
    .dropdown-list {
        background: #FFF;
        border-radius: 5px;
        border: 2px solid #000;
        color: #000;
        display: none;
        left: 0;
        max-height: 10rem;
        overflow: auto;
        text-align: left;
        top: 100%;
        position: absolute;
        right: 0;
        z-index: 2;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        li{
            cursor: pointer;
            padding: 0.4rem 1rem;
        }
        li.hover {
            background: rgba(0, 0, 0, 0.10);
        }
    }

    &.hover,
    &:focus,
    &:active {
        background-color: #ffc8a4;
        border-color: #F37521;
        outline: none;
    }
    &:focus-within {
        background-color: #ffc8a4;
        border-color: #F37521;
        outline: none;
    }

    &.active .dropdown-list{
        display: block;
    }

}

.mouse .dropdown:hover {
    background-color: #ffc8a4;
    border-color: #F37521;
    outline: none;
}
.mouse .dropdown-list li:hover{
    background: rgba(0, 0, 0, 0.10);
}