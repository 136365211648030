/* GENERAL */
html, body {
    background-color: #1f1a17;
    border: 0;
    color: #FFF;
    font-size: 24px;
    margin: 0;
    padding: 0;
}
body {
    min-height: 100vh;
}
html, body, input, select, button {
    font-family: Arial, Helvetica, sans-serif;
}
*, *::after, *::before {
    box-sizing: border-box;
    outline: none;
}
h1 {
    font-size: 2rem;
}
h2 {
    font-size: 1.2rem;
}
h3 {
    font-size: 1rem;
}
a {
    color: #FFF;
    text-decoration: none;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 3px;
}
input {
    background: #FFF;
    border: 2px solid transparent;
    border-radius: 5px;
    color: #000;
    display: block;
    font-size: 1rem;
    height: 2.6rem;
    margin-bottom: 0.85rem;
    padding: 5px 1rem;
    transition: border 0.2s ease-in-out;
    width: 100%;

    &.hover,
    &:focus,
    &:active {
        background: #ffc8a4;
        border-color: #F37521;
        outline: none;
    }

}
.mouse input:hover {
    background-color: #ffc8a4;
    border-color: #F37521;
    outline: none;
}

button {
    background: #000;
    border: 0;
    border-radius: 5px;
    color: #FFF;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    height: 3.12rem;
    text-transform: uppercase;
    transition: background 0.2s ease-in-out;
    padding: 0 0.83rem;
    width: 100%;

    &.hover,
    &:focus,
    &:active {
        background: #F37521;
    }

}
.mouse button:hover {
    background: #F37521;
}

input[type=range] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    cursor: pointer;
    height: 8.4px;
    margin: 0;
    padding: 0;
    width: 100%;

    &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        appearance: none;
        border-radius: 1.3px;
        cursor: pointer;
        width: 100%;
    }
    &::-moz-range-track {
        border: 1px solid transparent;
        background: transparent;
        border-radius: 1.3px;
        cursor: pointer;
        height: 8.4px;
        width: 100%;
    }

    &:focus::-webkit-slider-runnable-track {
        background: transparent;
    }
    &:focus::-moz-range-track {
        outline: none;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        background: #FFF;
        border-radius: 16px;
        cursor: pointer;
        height: 16px;
        margin-left: -1px;
        width: 16px;
    }
    &::-moz-range-thumb {
        background: #FFF;
        border: 1px solid #FFF;
        border-radius: 100%;
        cursor: pointer;
        height: 14px;
        margin-top: 5px;
        width: 14px;
    }
}

progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    height: 8.4px;
    pointer-events: none;
    width: 100%;

    &::-webkit-progress-bar {
        background: #c25d1b;
        border-radius: 5px;
    }
    &::-webkit-progress-value {
        background: #FFF;
        border-radius: 5px 0 0 5px;
    }
    &::-moz-progress-bar {
        background: #FFF;
        border: 1px solid #FFF;
    }
}

.hidden {
    display: none !important;
}

@media screen and (max-width: 1050px) {
    html, body {
        font-size: 20px;
    }
}

@media screen and (max-width: 860px) {
    html, body {
        font-size: 16px;
    }
}

@media screen and (max-width: 640px) {
    html, body {
        font-size: 14px;
    }
}