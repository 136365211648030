/* CONTENT */
#main {
    min-width: 300px;
}

#content {
    padding: 1rem;

    .inside {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
    }
    h1 {
        flex: 1 1 100%;
        margin: 0;
        padding: 0 0 0.83rem;

        &.hover {
            color: #F37521;
        }
    }
}

#content .error-message {
    background: #a50404;
    border-radius: 0.25rem;
    margin-top: 0.83rem;
    padding: 10px;

    p {
        margin: 0;
    }
}

#content .list-header {
    align-items: flex-start;
    display: flex;
    flex: 1 1 100%;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 0.83rem;

    h1 {
        flex: 1;
        margin-right: 1rem;
    }

    .add-to-watchlist,
    .remove-from-watchlist {
        flex: 0 1 10rem;
        height: auto;
        padding: 0.5rem 0.83rem;

        span {
            display: inline-block;
            vertical-align: middle;
        }
        span.icon {
            background-image: url(../icons/watchlist.svg);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            content: '';
            display: inline-block;
            height: 28px;
            vertical-align: middle;
            width: 28px;   
        }
    }
}

#content .list-info {
    margin-bottom: 1rem;
}

#content .list-filters {
    align-items: flex-start;
    display: flex;
    flex: 1 1 100%;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -1rem 0.83rem 0;

    input,
    .dropdown {
        background: transparent;
        border-color: #FFF;
        color: #FFF;
        margin-bottom: 0.5rem;
        margin-right: 1rem;
    }

    input {
        flex: 0 1 20rem;
    }

    .dropdown {
        flex: 1; 
    }
    .dropdown + .dropdown {
        flex: 0;
    }
    .dropdown:focus-within {
        border-color: #F37521;
    }
    .dropdown .dropdown-value:after {
        filter: invert(1);
    }

    input.hover,
    input:focus,
    input:active,
    .dropdown.hover,
    .dropdown:focus,
    .dropdown:active {
        border-color: #F37521;
    }

    button {
        border: 2px solid #FFF;
        flex: 0 0 auto;
        height: 2.6rem;
        width: auto;
    }

}
.mouse #content .list-filters input:hover,
.mouse #content .list-filters .dropdown:hover {
    border-color: #F37521;
}

#content .list-items {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;

    .list-item {
        align-items: stretch;
        display: flex;
        flex: 1 1 20%;
        justify-content: stretch;
        margin-bottom: 0.5rem;
        max-width: 20%;
        padding: 0.5rem;
    }
    .list-item-inside {
        background: #000;
        border: 1px solid #FFF;
        border-bottom-width: 10px;
        border-left-width: 2px;
        border-radius: 5px 5px 2px 2px;
        border-right-width: 2px;
        border-top-width: 2px;
        cursor: pointer;
        display: block;
        position: relative;
        transition: all 0.2s ease-in-out;
        width: 100%;

        &.hover {
            border-color: #F37521;
        }
    }

}
.mouse #content .list-item-inside:hover {
    border-color: #F37521;
}

#content .list-item-image {
    min-width: 6rem;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    width: 100%;

    img {
        border-radius: 5px 5px 2px 2px;
        bottom: 0;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }
}

#content .list-item-progress {
    background-color: #F37521;
    bottom: 0px;
    height: 10px;
    left: 0;
    position: absolute;
    width: 0%;
}

#content .list-item-premium {
    background: #F37521 url(../icons/premium.svg) no-repeat center;
    background-size: 1.6rem auto;
    border-radius: 0.25rem;
    height: 2rem;
    padding: 0.25rem;
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;
    width: 2rem;
}

#content .list-item-info {
    background: rgba(0, 0, 0, 0.75);
    border-radius: 0 0 2px 2px;
    overflow: hidden;
    padding: 0.83rem;
    text-overflow: ellipsis;
    transition: all 0.2s ease-in-out;
    word-break: break;

    h2,
    h3 {
        margin: 0;
    }
    h2 + h3 {
        font-weight: normal;
        margin-top: 0.83rem;
    }

    &.hover{
        background: #000;
    }
}
.mouse #content .list-item-info:hover {
    background: #000;
}

#content .list-pagination {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 1rem 0;

    a {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        padding: 10px;
    }
    a[href=""] {
        opacity: 0;
        pointer-events: none;
    }
    a.hover {
        background: #F37521;
        color: #FFF;
    }
}
.mouse #content .list-pagination a:hover {
    background: #F37521;
    color: #FFF;
}

#content .explore-item {

    .list-item-image {
        padding: 0;

        img {
            border-radius: 2px 2px 0 0;
            position: static;
        }
    }

}

@media screen and (max-width: 1400px) {
    #content .list-items .list-item {
        flex-basis: 25%;
        max-width: 25%;
    }
}

@media screen and (max-width: 1200px) {
    #content .list-items .list-item {
        flex-basis: 33.33%;
        max-width: 33.33%;
    }
}

@media screen and (max-width: 840px) {
    #content .list-items .list-item {
        flex-basis: 50%;
        max-width: 50%;
    }
}

@media screen and (max-width: 480px) {
    #content .list-items .list-item {
        flex-basis: 100%;
        max-width: 100%;
    }
}